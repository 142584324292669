#app-menu {
  .mobile-header-btn {
    @apply flex h-10 w-10 items-center justify-center rounded-lg border border-[#00B5FF86] bg-[#0091F714]  bg-cover bg-no-repeat p-0 disabled:border-white/10 disabled:saturate-50;
  }

  .ant-badge-dot {
    @apply right-1 top-1 h-3 w-3 bg-orange shadow-none;
  }
}

.share-tooltip {
  .ant-tooltip-inner {
    @apply rounded-xl border border-solid border-gray bg-gray/5 px-6 py-2;
  }
}
