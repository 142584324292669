@import '../../../styles/modal';

.api-error-modal {
  @extend .modal;

  .ant-modal-content {
    @apply bg-white/80 backdrop-blur-md;

    .ant-modal-body {
      .content {
        @apply text-center;

        .anticon {
          @apply text-5xl text-red;
        }
      }
    }
  }
}
