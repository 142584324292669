.auth-form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px #f4f4f4 inset !important;
    -webkit-text-fill-color: #000000;
  }

  &.ant-form {
    > .ant-form-item {
      @apply mb-3;

      .ant-form-item-label label {
        @apply block md:text-xl font-normal text-black;

        &::after {
          @apply hidden;
        }
      }
    }

    .ant-input-affix-wrapper {
      @apply h-12 md:h-14 rounded-lg bg-gray-light border-0 shadow-none md:text-base;

      &.ant-input-status-error {
        @apply bg-gray-light shadow-none;
      }

      .ant-input {
        @apply bg-transparent;
      }
    }

    .ant-checkbox {
      .ant-checkbox-inner {
        @apply w-5 h-5 border-2 border-primary;
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          @apply bg-transparent;

          &::after {
            @apply bg-primary rotate-0 transition-none start-1/2 -translate-x-1/2 -translate-y-2/4 border-0 w-3 h-3 rounded-1;
          }
        }
      }
    }

    .ant-select {
      @apply h-12 md:h-14;

      .ant-select-selector {
        @extend .ant-input-affix-wrapper;
        @apply pl-11 text-black;

        .ant-select-selection-search {
          inset-inline-start: 0;
          @apply pl-11;
        }
      }
    }

    .error-input input {
      @apply text-red;
    }
  }
}

.profile-form.ant-form {
  .ant-form-item {
    @apply mb-3;

    &.ant-form-item-has-error {
      .ant-input {
        @apply border-red;
      }
    }

    .ant-input {
      @apply rounded-lg bg-white/5 border-transparent shadow-none text-white placeholder:text-white/70;
    }

    .ant-picker {
      @extend .ant-input;

      input {
        @apply text-white placeholder:text-white/70;
      }

      &:hover {
        .ant-picker-clear {
          @apply bg-black backdrop-opacity-0;

          &:hover {
            @apply text-white;
          }
        }
      }
    }

    .ant-select {
      &.ant-select-focused,
      &:hover {
        .ant-select-selector {
          @apply border-transparent shadow-none;
        }
      }

      &.ant-select-open {
        .ant-select-selection-item {
          @apply text-white/50;
        }
      }

      .ant-select-selector {
        @extend .ant-input;

        .ant-select-selection-search-input {
          @apply text-white placeholder:text-white/70;
        }

        .ant-select-selection-placeholder {
          @apply text-white/70;
        }
      }

      .ant-select-arrow {
        @apply text-white/70;
      }
    }

    .ant-checkbox-wrapper {
      @apply text-white flex items-center;

      &.ant-checkbox-wrapper-checked {
        .ant-checkbox {
          .ant-checkbox-inner::after {
            @apply border-primary;
          }
        }
      }
      .ant-checkbox {
        @apply rounded-lg bg-white/5 p-1.5;

        .ant-checkbox-inner {
          @apply bg-transparent border-white;

          &::after {
            @apply start-1/3;
          }
        }
      }
    }
  }
}

.profile-select-dropdown {
  @apply bg-white/5 backdrop-blur-md;

  .ant-select-item {
    @apply text-white;

    &.ant-select-item-option-selected {
      @apply bg-black/60 text-white;
    }
  }
}
