.modal {
  &.ant-modal {
    .ant-modal-content {
      @apply rounded-3xl p-0;

      .content {
        @apply text-left py-8 px-10 flex-col;

        .icon {
          @apply mb-4;
        }

        .title {
          @apply mt-0 mb-2;
        }

        .text {
          @apply mt-0 mb-4;
        }
      }
    }
  }
}

.profile-modal.ant-modal {
  padding-block: 16px !important;

  .ant-modal-content {
    @apply rounded-2xl bg-black/70 backdrop-blur-md border border-solid border-white/5 p-4 md:p-8;

    .ant-progress {
      @apply leading-0;

      .ant-progress-inner {
        @apply bg-white;
      }
    }

    .agreement-content {
      @apply flex items-center mt-6;

      .header {
        @apply flex-grow;

        .title {
          @apply text-base font-medium text-white mt-0 mb-1;
        }

        .description {
          @apply text-gray m-0;
        }
      }

      .ant-switch {
        @apply h-3;

        &:not(.ant-switch-checked) {
          @apply bg-gray;

          .ant-switch-handle {
            @apply start-0;
          }
        }

        .ant-switch-handle {
          @apply w-5 h-5 -top-1;

          &::before {
            @apply rounded-full;
          }
        }
      }
    }
  }
}

.benefit-modal.ant-modal {
  .ant-modal-content {
    @apply rounded-2xl bg-black p-4;
  }
}

.tooltip-modal.ant-modal {
  .ant-modal-content {
    @apply rounded-2xl bg-black p-4;
  }
}

.app-message {
  top: auto !important;
  bottom: 5%;

  .app-message-notice {
    .app-message-notice-content {
      @apply rounded-xl backdrop-blur-2xl border border-solid text-base text-white;

      .app-message-custom-content {
        @apply flex items-center;
      }
    }

    &.app-message-notice-success {
      .app-message-notice-content {
        @apply bg-green/5 border-green;
      }
    }

    &.app-message-notice-error {
      .app-message-notice-content {
        @apply bg-red/10 border-red;
      }
    }
  }
}

.profile-edit-message {
  top: auto !important;
  bottom: 5%;

  .profile-edit-message-notice {
    .profile-edit-message-notice-content {
      @apply rounded-10 border-none;

      .profile-edit-message-custom-content {
        @apply border-none flex items-center rounded-[9px] p-1;
      }
    }

    &.profile-edit-message-notice-info {
      .profile-edit-message-notice-content {
        @apply text-white;
        background-color: #335165;
      }
    }
    &.profile-edit-message-notice-success {
      .profile-edit-message-notice-content {
        background-color: #3b4d44;
        color: #44e095;
      }
    }

    &.profile-edit-message-notice-error {
      .profile-edit-message-notice-content {
        background-color: #5d3b3f;
        color: #ff858a;
      }
    }
  }
}

.app-popover {
  .ant-popover-inner {
    @apply rounded-xl bg-black/70 backdrop-blur-md p-2;
  }
}
