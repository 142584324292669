.subs-period-radio-button-group {
  @apply border border-solid border-white rounded-2xl bg-black/70;

  .ant-radio-button-wrapper {
    @apply border-none h-11 inline-flex items-center text-base;

    &::before {
      @apply h-4 inset-y-2/4 -translate-y-2/4 bg-gray;
    }

    &:hover {
      @apply text-gray;

      &::before {
        @apply bg-gray;
      }
    }

    &.ant-radio-button-wrapper-checked {
      @apply text-white font-medium;
    }
  }
}

.question-radio-group {
  @apply w-full;

  .ant-radio-wrapper {
    @apply w-full text-white md:text-base px-4 py-3 rounded-xl bg-white/5;

    &.ant-radio-wrapper-checked {
      @apply bg-white/20;

      .ant-radio-inner::after {
        @apply bg-primary;
      }
    }
  }
}

.leaderboard-radio-button-group {
  @apply flex justify-around gap-8 rounded-2xl px-4 py-3 bg-gray-dark/35;

  .ant-radio-button-wrapper {
    @apply max-w-12 text-center border-0 p-0 h-auto text-10 leading-normal font-bold;

    &::before {
      @apply hidden;
    }

    &.ant-radio-button-wrapper-checked,
    &:hover {
      .anticon {
        path {
          @apply fill-primary;
        }
      }
    }

    .anticon {
      @apply text-2xl;
    }
  }

  &.small {
    @apply gap-0 rounded-lg p-1;

    .ant-radio-button-wrapper {
      @apply px-1.5;

      &::before {
        @apply block h-2 opacity-20 top-2/4 -translate-y-2/4;
      }

      .anticon {
        @apply text-base;
      }
    }
  }
}

.leaderboard-radio-button-group-new {
  @apply flex justify-around items-center gap-0 rounded-5 bg-black/25;

  .ant-radio-button-wrapper {
    @apply max-w-12 text-center border-0 h-auto text-10 leading-normal font-bold py-1.5 px-1.5;

    &::before {
      @apply hidden;
    }

    &.ant-radio-button-wrapper-checked,
    &:hover {
      .anticon {
        path {
          @apply fill-blue-accent;
        }
      }
    }
    &.ant-radio-button-wrapper-checked {
      @apply outline outline-blue-accent/50 bg-blue-accent/10 rounded outline-offset-0 outline-1;
    }

    .anticon {
      @apply text-xl;
    }
  }

  &.small {
    @apply gap-0 rounded-lg p-1;

    .ant-radio-button-wrapper {
      @apply px-1.5;

      &::before {
        @apply block h-2 opacity-20 top-2/4 -translate-y-2/4;
      }

      .anticon {
        @apply text-base;
      }
    }
  }
}
