@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/scss';
@import 'styles/form';
@import 'styles/modal';
@import 'styles/table';
@import 'styles/radio';
@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/grid';
@import 'swiper/css/navigation';
@import 'react-phone-input-2/lib/style.css';

@layer base {
  :root {
    background-color: #181818;
  }
}

@layer utilities {
  .black-box {
    @apply overflow-hidden rounded-2xl bg-black/70;
  }

  .section-header {
    @apply flex items-center justify-between border-0 border-b border-solid border-white/15 pb-3 pr-4 pt-5 text-white;
  }

  .section-filter-select.ant-select {
    &,
    &.ant-select-focused.ant-select:not(.ant-select-disabled) {
      & .ant-select-selector {
        @apply rounded-md border border-white/20 bg-black/25 text-sm text-white shadow-none;
      }
      & .ant-select-selection-item {
        @apply text-white;
      }
    }

    .ant-select-arrow {
      @apply text-10 text-white;
    }
  }

  .section-filter-select-dropdown {
    @apply bg-black-light;

    .ant-select-item {
      @apply min-h-fit text-sm text-white;

      &.ant-select-item-option-selected {
        @apply rounded-md bg-gray text-white;
      }
    }
  }

  .app-swiper {
    @apply h-full;

    .swiper-pagination {
      @apply flex items-center justify-center;

      .swiper-pagination-bullet {
        @apply mx-0.5 h-1 w-1 rounded-1 bg-white opacity-50;

        &.swiper-pagination-bullet-active {
          @apply h-2 w-2 rounded-3 opacity-100;
        }
      }
    }
    .custom-bullet {
      @apply mx-2 mb-1 inline-block h-2 w-2 rounded-full border border-white bg-white/20;
    }
    .custom-bullet-active {
      @apply bg-white;
    }
  }

  .word-break {
    word-break: break-word;
  }

  .error-icon path {
    @apply fill-red;
  }

  .white-icon path {
    @apply fill-white;
  }

  .react-tel-input .flag-button {
    &.open {
      .selected-flag {
        @apply bg-transparent;

        &:hover {
          @apply bg-transparent;
        }
      }
    }
    .selected-flag {
      @apply bg-transparent;

      &:hover {
        @apply bg-transparent;
      }
    }
  }

  .gradient-colorful-bg {
    @apply bg-gradient-to-r from-blue-turq to-green-light;
  }

  .gradient-colorful-text {
    @extend .gradient-colorful-bg;
    @apply bg-clip-text text-transparent;
  }
}

@font-face {
  font-family: 'Figtree';
  src: url('/assets/fonts/Figtree-VariableFont_wght.ttf');
}

body {
  margin: 0;
  font-family:
    'Figtree',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  .app-loading {
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
}

.glitch-text {
  position: relative;
  font-size: 10vw;
  font-weight: 900;
  transform-style: preserve-3d;
}

.glitch-text::before {
  content: 'Tribünde \a Yerini Al!';
  position: absolute;
  inset: 1px;
  transform: translateZ(-1px);
  left: -3px;
  top: 0.6rem;
  background: linear-gradient(90deg, #3d8aaa 0.09%, #111 100.03%);
  background-size: 100% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  white-space: pre;
  width: -moz-fit-content;
  width: fit-content;
}
@media not all and (min-width: 768px) {
  .glitch-text::before {
    left: -3px;
    top: 0.3rem;
  }
}
